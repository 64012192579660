var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"bottom-line"}),_c('div',{staticClass:"content"},[_c('div',{staticClass:"tabs-container"},[_c('span',{staticClass:"title",class:{ active: _vm.activeItem === 'news' },on:{"click":function($event){return _vm.changeRoute('news')}}},[_vm._v(_vm._s(_vm._tr("News")))]),_c('span',{staticClass:"title",class:{ active: _vm.activeItem === 'photos' },on:{"click":function($event){return _vm.changeRoute('photos')}}},[_vm._v(_vm._s(_vm._tr("Photos")))]),_c('span',{staticClass:"title",class:{ active: _vm.activeItem === 'videos' },on:{"click":function($event){return _vm.changeRoute('videos')}}},[_vm._v(_vm._s(_vm._tr("Videos")))])]),_c('div',{staticClass:"filter-nav"},[_c('date-picker',{attrs:{"popover":{
                    placement: _vm.$screens({
                        default: 'bottom',
                        md: 'bottom-start',
                        sm: 'bottom-start'
                    }),
                    modifiers: [
                        {
                            name: 'flip',
                            options: {
                                fallbackPlacements: ['bottom', 'bottom']
                            }
                        }
                    ]
                },"is-range":"","attributes":_vm.att,"value":_vm.value},on:{"popoverWillHide":function($event){_vm.show = false},"popoverWillShow":function($event){_vm.show = true},"input":_vm.updateDate},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var togglePopover = ref.togglePopover;
return [(!_vm.dateSelected)?_c('div',{staticClass:"toggle-button",on:{"click":function($event){return togglePopover()}}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm._tr("Filter by date")))]),(!_vm.dateSelected)?_c('svg',{staticClass:"icon",class:{ up: _vm.show },attrs:{"width":"14.738","height":"15.99","viewBox":"0 0 14.738 15.99"}},[_c('g',{attrs:{"id":"Group_55","data-name":"Group 55","transform":"translate(13.324 14.576) rotate(180)"}},[_c('line',{attrs:{"id":"Line_3","data-name":"Line 3","x2":"5.968","y2":"6.243","transform":"translate(5.943 0)","fill":"none","stroke":"#E5B676","stroke-linecap":"round","stroke-width":"2"}}),_c('line',{attrs:{"id":"Line_4","data-name":"Line 4","x1":"5.943","y2":"6.243","transform":"translate(0 0)","fill":"none","stroke":"#E5B676","stroke-linecap":"round","stroke-width":"2"}}),_c('line',{attrs:{"id":"Line_5","data-name":"Line 5","y2":"12.704","transform":"translate(5.955 0.872)","fill":"none","stroke":"#E5B676","stroke-linecap":"round","stroke-width":"2"}})])]):_vm._e()]):_c('div',{staticClass:"clear-btn",on:{"click":_vm.clearDate}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm._tr("Clear Date")))]),_c('div',{staticClass:"clear-btn__icon"},[_c('svg',{attrs:{"viewPort":"0 0 12 12","version":"1.1","xmlns":"http://www.w3.org/2000/svg"}},[_c('line',{attrs:{"x1":"1","y1":"11","x2":"11","y2":"1","stroke":"#e5b676","stroke-width":"2"}}),_c('line',{attrs:{"x1":"1","y1":"1","x2":"11","y2":"11","stroke":"#e5b676","stroke-width":"2"}})])])])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }