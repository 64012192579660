import axios from "@/scripts/api";
import imagesLoaded from "imagesloaded";
import {mapGetters} from "vuex";
import moment from "moment";

export default {
    data() {
        return {
            pending: false,
            pagingDataList: null,
            prev: '<div class="prev">\n' +
                '<svg xmlns="http://www.w3.org/2000/svg" width="15.99" height="14.737" viewBox="0 0 15.99 14.737">\n' +
                '  <g id="Group_424" data-name="Group 424" transform="translate(1.414 13.324) rotate(-90)">\n' +
                '    <line id="Line_3" data-name="Line 3" x2="5.968" y2="6.243" transform="translate(5.943 0)" fill="none" stroke="#E5B676" stroke-linecap="round" stroke-width="2"/>\n' +
                '    <line id="Line_4" data-name="Line 4" x1="5.943" y2="6.243" transform="translate(0 0)" fill="none" stroke="#E5B676" stroke-linecap="round" stroke-width="2"/>\n' +
                '    <line id="Line_5" data-name="Line 5" y2="12.704" transform="translate(5.955 0.872)" fill="none" stroke="#E5B676" stroke-linecap="round" stroke-width="2"/>\n' +
                '  </g>\n' +
                '</svg>\n</div>',
            next: '<div class="next">\n' +
                '<svg xmlns="http://www.w3.org/2000/svg" width="15.99" height="14.737" viewBox="0 0 15.99 14.737">\n' +
                '  <g id="Group_424" data-name="Group 424" transform="translate(1.414 13.324) rotate(-90)">\n' +
                '    <line id="Line_3" data-name="Line 3" x2="5.968" y2="6.243" transform="translate(5.943 0)" fill="none" stroke="#E5B676" stroke-linecap="round" stroke-width="2"/>\n' +
                '    <line id="Line_4" data-name="Line 4" x1="5.943" y2="6.243" transform="translate(0 0)" fill="none" stroke="#E5B676" stroke-linecap="round" stroke-width="2"/>\n' +
                '    <line id="Line_5" data-name="Line 5" y2="12.704" transform="translate(5.955 0.872)" fill="none" stroke="#E5B676" stroke-linecap="round" stroke-width="2"/>\n' +
                '  </g>\n' +
                '</svg>\n</div>',
            activePage: 1,
            date: null
        }
    },
    computed: {
        ...mapGetters({
            device: 'resize/geDevice',
            activePageId: 'menu/getActivePageId',
            scroll: 'scroll/getScroll'
        }),
        page() {
            return this.$route?.query?.page
        },
        dateRange() {
            return this.date ? [moment(this.date.start).format('YYYY-MM-DD'), moment(this.date.end).format('YYYY-MM-DD')] : null
        },
        pageCount() {
            return Math.ceil(this.data?.listCount / Number(this.config?.page_count)) || 1
        }
    },
    methods: {
        getDataList(firstLoad, page) {
            this.pending = true
            axios('/getDataList', {
                id: Number(this.activePageId),
                pageNumber: page,
                componentUnicId: this.config?.unicId,
                searchDate: this.dateRange
            }).then((response) => {
                this.pending = false
                this.pagingDataList = response.data
                setTimeout(() => {
                    imagesLoaded(document.querySelector('.faded-content-for-list-update'), () => {
                        if (firstLoad) {
                            this.gsap.to('.paging-rout-changer', {
                                opacity: 0
                            })
                        } else {
                            this.gsap.to('.faded-content-for-list-update', {
                                opacity: 1,
                                duration: .4
                            })
                        }
                        this.scroll?.update()
                        this.scroll.scrollTo('top', {
                            disableLerp: true,
                            duration: .01
                        });
                    })
                }, 300)
            }).catch(function () {
                this.pending = false
            })
        },
        getDataListHandler(firstLoad = false, page) {
            if (firstLoad && (this.page === 1 || !this.page)) return
            if (firstLoad) {
                this.gsap.set('.paging-rout-changer', {
                    opacity: 1
                })
                this.getDataList(firstLoad, page)
            } else {
                this.gsap.to('.faded-content-for-list-update', {
                    opacity: 0,
                    duration: .4,
                    onComplete: () => {
                        this.getDataList(firstLoad, page)
                    }
                })

            }

        },
        addParamsToLocation(params) {
            history.pushState(
                {},
                null,
                this.$route.path +
                '?' +
                Object.keys(params)
                    .map(key => {
                        return (
                            encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
                        )
                    })
                    .join('&')
            )
        },
        paginationClickCallback(pageNum) {
            this.addParamsToLocation({page: pageNum})
            this.getDataListHandler(false, pageNum)
        }
    },
    created() {
        this.getDataListHandler(true, this.page)
        this.activePage = Number(this.page)
        this.$root.$on(`date_updated`, (date) => {
            this.date = date
            this.paginationClickCallback(1)
            this.activePage = 1
        })
    }
}
