<template>
    <div class="header">
        <div class="bottom-line"></div>
        <div class="content">
            <div class="tabs-container">
                <span
                    @click="changeRoute('news')"
                    class="title"
                    :class="{ active: activeItem === 'news' }"
                    >{{ _tr("News") }}</span
                >
                <span
                    @click="changeRoute('photos')"
                    class="title"
                    :class="{ active: activeItem === 'photos' }"
                    >{{ _tr("Photos") }}</span
                >
                <span
                    @click="changeRoute('videos')"
                    class="title"
                    :class="{ active: activeItem === 'videos' }"
                    >{{ _tr("Videos") }}</span
                >
            </div>
            <div class="filter-nav">
                <date-picker
                    @popoverWillHide="show = false"
                    @popoverWillShow="show = true"
                    @input="updateDate"
                    :popover="{
                        placement: $screens({
                            default: 'bottom',
                            md: 'bottom-start',
                            sm: 'bottom-start'
                        }),
                        modifiers: [
                            {
                                name: 'flip',
                                options: {
                                    fallbackPlacements: ['bottom', 'bottom']
                                }
                            }
                        ]
                    }"
                    is-range
                    :attributes="att"
                    :value="value"
                >
                    <template v-slot="{ togglePopover }">
                        <div
                            @click="togglePopover()"
                            class="toggle-button"
                            v-if="!dateSelected"
                        >
                            <span class="title">{{
                                _tr("Filter by date")
                            }}</span>
                            <svg
                                class="icon"
                                :class="{ up: show }"
                                width="14.738"
                                height="15.99"
                                viewBox="0 0 14.738 15.99"
                                v-if="!dateSelected"
                            >
                                <g
                                    id="Group_55"
                                    data-name="Group 55"
                                    transform="translate(13.324 14.576) rotate(180)"
                                >
                                    <line
                                        id="Line_3"
                                        data-name="Line 3"
                                        x2="5.968"
                                        y2="6.243"
                                        transform="translate(5.943 0)"
                                        fill="none"
                                        stroke="#E5B676"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_4"
                                        data-name="Line 4"
                                        x1="5.943"
                                        y2="6.243"
                                        transform="translate(0 0)"
                                        fill="none"
                                        stroke="#E5B676"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                    <line
                                        id="Line_5"
                                        data-name="Line 5"
                                        y2="12.704"
                                        transform="translate(5.955 0.872)"
                                        fill="none"
                                        stroke="#E5B676"
                                        stroke-linecap="round"
                                        stroke-width="2"
                                    />
                                </g>
                            </svg>
                        </div>
                        <div v-else class="clear-btn" @click="clearDate">
                            <span class="title">{{ _tr("Clear Date") }}</span>
                            <div class="clear-btn__icon">
                                <svg
                                    viewPort="0 0 12 12"
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <line
                                        x1="1"
                                        y1="11"
                                        x2="11"
                                        y2="1"
                                        stroke="#e5b676"
                                        stroke-width="2"
                                    />
                                    <line
                                        x1="1"
                                        y1="1"
                                        x2="11"
                                        y2="11"
                                        stroke="#e5b676"
                                        stroke-width="2"
                                    />
                                </svg>
                            </div>
                        </div>
                    </template>
                </date-picker>
            </div>
        </div>
    </div>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import { mapActions, mapGetters } from "vuex";

export default {
    data() {
        return {
            att: [
                {
                    popoverWillShow: () => {},
                    popoverWillHide: () => {}
                }
            ],
            value: new Date(),
            show: false,
            dateSelected: false
        };
    },
    watch: {
        // value: {
        //     immediate: true,
        //     handler(val) {
        //         console.log(val);
        //     }
        // }
    },
    computed: {
        ...mapGetters({
            lang: "language/getLang",
            menu: "menu/getCurrentPageMenuData"
        }),
        activeItem() {
            return this.menu?.url_slug;
        }
    },
    methods: {
        ...mapActions({
            getPageData: "menu/getPageData"
        }),
        changeRoute(slug) {
            this.$router
                .push({ path: `/${this.lang}/media-events/${slug}` })
                .catch(e => {
                    console.log(e);
                });
        },
        updateDate(date) {
            this.dateSelected = true;
            this.$root.$emit(`date_updated`, date);
        },

        clearDate() {
            this.dateSelected = false;
            this.value = new Date();
            this.$root.$emit(`date_updated`, "");
        }
    },
    components: {
        DatePicker
    },
    mounted() {}
};
</script>

<style lang="scss" scoped>
$ease-out: cubic-bezier(0.15, 0.16, 0.37, 1);

.header {
    position: relative;
    background: #f3efe6;
    padding: 55px 0 150px;
    @media only screen and (max-width: 1440px) and (min-width: 1024px) {
        padding: 35px 0 100px;
    }
    @media only screen and (max-width: 1023px) and (min-width: 768px) {
        padding: 30px 0 75px;
    }
    @media only screen and (max-width: 767px) {
        padding: 25px 0 50px;
        z-index: 12;
    }

    .content {
        @include space-between;
        padding: 0 225px;
        margin: 0 auto;
        z-index: 9;
        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            padding: 0 100px;
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            padding: 0 55px;
        }
        @media only screen and (max-width: 767px) {
            padding: 0 15px;
        }
        @media only screen and (max-width: 500px) {
            flex-direction: column;
        }

        .tabs-container {
            display: flex;
            align-items: center;

            .title {
                font-weight: normal;
                font-size: 21px;
                color: #0a141c;
                cursor: pointer;
                transition: all 0.4s $ease-out;
                margin-left: 40px;

                &:first-child {
                    margin-left: 0;
                }

                @media only screen and (max-width: 767px) {
                    font-size: 18px;
                    margin-left: 25px;
                }

                &.active,
                &:hover {
                    color: #e5b676;
                    text-decoration: underline;
                    text-decoration-thickness: 2px;
                    text-underline-offset: 7px;
                }
            }
        }

        .filter-nav {
            @media only screen and (max-width: 500px) {
                margin-top: 30px;
            }

            .toggle-button {
                cursor: pointer;
                display: flex;
                align-items: center;

                .icon {
                    transition: all 0.4s $ease-out;
                    margin-left: 10px;

                    &.up {
                        transform: rotate(180deg);
                    }
                }
            }
            .clear-btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                &__icon {
                    width: 15px;
                    height: 15px;
                    margin-left: 15px;
                    svg {
                        width: 15px;
                        height: 15px;
                    }
                }
            }
        }
    }

    .bottom-line {
        position: absolute;
        bottom: 0;
        background: #e5b676;
        width: calc(100% - 150px);
        height: 1px;
        left: 50%;
        transform: translateX(-50%);

        @media only screen and (max-width: 1440px) and (min-width: 1024px) {
            width: calc(100% - 100px);
        }
        @media only screen and (max-width: 1023px) and (min-width: 768px) {
            width: calc(100% - 70px);
        }
        @media only screen and (max-width: 767px) {
            width: calc(100% - 20px);
        }
    }
}
</style>
